
<div class="animated fadeIn">
  
    <div *ngFor="let parameter of parameters" class="alert alert-secondary">
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-select placeholder="Sensor Type" [(ngModel)]="parameter.SensorTypeId">
              <mat-option *ngFor="let sensorType of sensorTypes" [value]="sensorType.Id">
                {{ sensorType.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Parameter Name</mat-label>
            <input matInput [(ngModel)]="parameter.ParameterName" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Default Value</mat-label>
            <input matInput [(ngModel)]="parameter.DefaultValue" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Description</mat-label>
            <input matInput [(ngModel)]="parameter.Description" />
          </mat-form-field>
        </div>
      </div>
    </div>
  

  <div class="buttons">
    <button mat-button (click)="add()" class="mr-1">ADD A PARAMETER</button>
    <button mat-raised-button (click)="save()" color="primary">SAVE</button>
  </div>
</div>

