import { Component } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { Site, SiteService } from '../../services/site.service';
import { Router } from '@angular/router';
import { SensorService, Sensor, SensorStatus } from '../../services/sensor.service';
import { MouseEvent } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { SensorComponent } from '../sensor/sensor.component';
import * as moment from 'moment';
import { GatewayService } from '../../services/gateway.service';
import { TrackerService, Tracker } from '../../services/tracker.service';
import { TrackerComponent } from '../tracker/tracker.component';


@Component({
  selector: 'app-perimeter',
  templateUrl: './perimeter.component.html',
})
export class PerimeterComponent { 

  SensorStatus = SensorStatus;
  roadMap = false;
  lock = true;
  selectedTrackerId: string;

  constructor(
    public applicationService: ApplicationService,
    public siteService: SiteService,
    public sensorService: SensorService,
    public trackerService: TrackerService,
    public gatewayService: GatewayService,
    public sensorDialog: MatDialog,
    public trackerDialog: MatDialog,
    public router: Router
  ) {
    this.applicationService.title = "Live Perimeter";
    if (!applicationService.site) {
      this.router.navigate(['/sites']);
    }
    else {
      this.sensorService.startWebSocket();
      this.trackerService.startWebSocket();
      this.sensorService.load();
      this.trackerService.load();
      this.gatewayService.load();
    }
  }

  mapLat() {
    if (this.selectedTrackerId) {
      return this.trackerService.trackers.find(i => i.Id == this.selectedTrackerId).Latitude;
    }
    return this.siteService.selectedSite.Latitude;
  }

  mapLng() {
    if (this.selectedTrackerId) {
      return this.trackerService.trackers.find(i => i.Id == this.selectedTrackerId).Longitude;
    }
    return this.siteService.selectedSite.Longitude;
  }

  get sensors(): Sensor[] {
    return this.sensorService.sensors;
  }

  get trackers(): Tracker[] {
    return this.trackerService.trackers;
  }

  get site(): Site {
    return this.siteService.selectedSite;
  }

  get mapTypeId(): string {
    return this.roadMap ? 'roadmap' : 'satellite';
  }
  
  ngOnInit() {
    $('agm-map').height(window.innerHeight - 150);
  }

  icon(sensor: any) {
    return this.sensorService.icon(sensor);
  }

  status(sensor: any) {
    return this.sensorService.status(sensor);
  }
  
  add() {

    this.sensorService.changeNav("");
    let sensor = this.sensor();
    const dialogRef = this.sensorDialog.open(SensorComponent, {
      data: sensor
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (sensor.Serial && sensor.GatewayId && sensor.SensorTypeId > 0) {
          this.sensorService.save(sensor);
        }
        else {
          this.applicationService.showError("Please specify sensor type, gateway and device id");
        }
      }
    });
  }

  addTracker() {
    
    let tracker = this.tracker();

    tracker.SiteId = this.siteService.selectedSite.Id;
    tracker.Latitude = this.siteService.selectedSite.Latitude;
    tracker.Longitude = this.siteService.selectedSite.Longitude;

    const dialogRef = this.trackerDialog.open(TrackerComponent, {
      data: tracker
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (tracker.IMEI) {
          this.trackerService.save(tracker);
        }
        else {
          this.applicationService.showError("Please specify tracker IMEI");
        }
      }
    });
  }

  reset(sensor: Sensor) {
    sensor.SensorStatus = SensorStatus.Normal;
    this.sensorService.save(sensor);
  }

  resetTracker(tracker: Tracker) {
    tracker.SensorStatus = SensorStatus.Normal;
    this.trackerService.save(tracker);
  }

  delete(sensor: Sensor) {
    if (window.confirm("Delete the selected sensor?")) {
      this.sensorService.delete(sensor);
    }
  }

  deleteTracker(tracker: Tracker) {
    if (window.confirm("Delete the selected tracker?")) {
      this.trackerService.delete(tracker);
    }
  }

  update(sensor: Sensor) {
    const dialogRef = this.sensorDialog.open(SensorComponent, {
      data: sensor
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (sensor.Serial && sensor.GatewayId) {
          this.sensorService.save(sensor);
        }
        else {
          this.applicationService.showError("Please specify both a gateway and device id");
        }
      }
    });
  }

  markerDragEnd(sensor: Sensor, $event: MouseEvent) {
    if (!this.lock) {
      sensor.Latitude = $event.coords.lat;
      sensor.Longitude = $event.coords.lng;
      this.sensorService.save(sensor);
    }
  }

  trackerDragEnd(tracker: Tracker, $event: MouseEvent) {
    if (!this.lock) {
      tracker.SetLatitude = $event.coords.lat;
      tracker.SetLongitude = $event.coords.lng;
      this.trackerService.save(tracker);
    }
  }

  tracker(): Tracker {
    return {
      Id: null,
      Name: "",
      IMEI: null,
      SensorStatus: SensorStatus.Normal,
      SetLatitude: this.site.Latitude,
      SetLongitude: this.site.Longitude,
      Latitude: this.site.Latitude,
      Longitude: this.site.Longitude,
      LastUpdate: new Date()

    } as Tracker;
  }

  sensor(): Sensor {
    return {
      Id: null,
      Name: "",
      Serial: null,
      GatewayId: "",
      SensorStatus: SensorStatus.Normal,
      Latitude: this.site.Latitude,
      Longitude: this.site.Longitude,
      Voltage: 0,
      RSSI: 0,
      Parameters: [],
      SensorTypeId: 1,
      LastUpdate: new Date(),

    } as Sensor;
  }
  
}
