import { Component } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { Site, SiteService } from '../../services/site.service';
import { Router } from '@angular/router';
import { SensorService, Sensor, SensorStatus } from '../../services/sensor.service';
import { GatewayService, Gateway } from '../../services/gateway.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
})
export class EquipmentComponent {

  dtOptions: DataTables.Settings = {};
  SensorStatus = SensorStatus;
  selectedSensor: Sensor;
  editing = false;
 
  constructor(
    private applicationService: ApplicationService,
    public siteService: SiteService,
    public sensorService: SensorService,
    public gatewayService: GatewayService,
    public router: Router
  ) {
    this.applicationService.title = "Equipment Settings";
    if (!applicationService.site) {
      this.router.navigate(['/sites']);
    }
    else {
      this.sensorService.stopWebSocket();
      this.sensorService.load();
    }
  }

  get sensor(): Sensor {
    return this.selectedSensor;
  }

  get sensors(): Sensor[] {
    return this.sensorService.sensors;
  }

  get site(): Site {
    return this.siteService.selectedSite;
  }

  get gateways(): Gateway[] {
    return this.gatewayService.gateways;
  }

  updateSettings(sensor: Sensor) {
    this.sensorService.sensors.filter(item => item.GatewayId == sensor.GatewayId).forEach((item) => {
      sensor.Parameters.forEach((p) => {
        let paramater = item.Parameters.find(sp => sp.Name == p.Name);
        if (paramater) {
          paramater.Value = p.Value;
        }
      });
    });
  }

  
  ngOnInit() {
    this.dtOptions = {
      order: [0, "desc"],
      lengthMenu: [10, 25, 50]
    };
  }

  loadHistory(sensor: Sensor) {
    this.sensorService.loadHistory(sensor);
  }

  icon(sensor: Sensor) {
    return this.sensorService.icon(sensor);
  }

  status(sensor: Sensor) {
    return this.sensorService.status(sensor);
  }

  type(sensor: Sensor) {
    if (sensor.SensorTypeId) {
      return "Fence Sensor";
    }
    else {
      return "N/A";
    }
  }

  packetType(index: number): string {
    return this.sensorService.packetType(index);
  }

  reset(sensor: Sensor) {
    sensor.SensorStatus = SensorStatus.Normal;
    this.sensorService.save(sensor);
  }

  save() {
    this.sensorService.save(this.selectedSensor);
    this.cancel();
  }

  update() {
    this.sensorService.update();
    this.cancel();
  }

  cancel() {
    this.editing = false;
    this.selectedSensor = null;
  }

  edit(sensor: Sensor) {
    this.selectedSensor = sensor;
    this.editing = true;
  }

  
  exitHistory() {
    return this.sensorService.exitHistory();
  }
  
}
