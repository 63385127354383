import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SitesComponent } from '../components/sites/sites.component';
import { PerimeterComponent } from '../components/perimeter/perimeter.component';
import { LoginComponent } from '../components/login/login.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { GatewayServerComponent } from '../components/server/server.component';
import { UsersComponent } from '../components/users/users.component';
import { EquipmentComponent } from '../components/equipment/equipment.component';
import { ParametersComponent } from '../components/parameters/parameters.component';
import { GatewayComponent } from '../components/gateways/gateways.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'sites', component: SitesComponent },
  { path: 'perimeter', component: PerimeterComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'server', component: GatewayServerComponent },
  { path: 'users', component: UsersComponent },
  { path: 'equipment', component: EquipmentComponent },
  { path: 'parameters', component: ParametersComponent },
  { path: 'gateways', component: GatewayComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
