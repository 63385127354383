

<div class="animated fadeIn">

  <div *ngIf="!sensorService.viewHistory">
    <div *ngIf="!editing">
      <table datatable [dtOptions]="dtOptions">
        <thead>
          <tr style="background-color: #f5f5f5">
            <th style="min-width: 50px">

            </th>
            <th>
              Gateway
            </th>
            <th>
              Serial
            </th>
            <th>
              RSSI
            </th>
            <th>
              SNR
            </th>
            <th>
              Voltage
            </th>
            <th>
              Status
            </th>
            <th>
              Last Update
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sensor of sensors">
            <th style="border-bottom: 1px solid #f5f5f5; vertical-align: middle; background-color: #f5f5f5; color: #f44336">
              <button mat-button (click)="loadHistory(sensor)"><img src="{{ icon(sensor) }}" style="margin-right: 6px" /> {{ sensor.Name }}</button>
            </th>
            <td>
              <mat-select [(ngModel)]="sensor.GatewayId">
                <mat-option *ngFor="let gateway of gateways" [value]="gateway.Id">
                  {{ gateway.Name }}
                </mat-option>
              </mat-select>
            </td>
            <td>
              <input type="number" [(ngModel)]="sensor.Serial" />
              <span style="display: none"> {{sensor.Serial}}</span>
            </td>
            <td>
              {{ sensor.RSSI }}
            </td>
            <td>
              {{ sensor.NS }}
            </td>
            <td>
              {{ sensor.Voltage / 10 }} V
            </td>
            <td>
              {{ status(sensor) }}
            </td>
            <td>
              <span *ngIf="sensor.LastUpdate">
                {{ sensor.LastUpdate | date: 'yyyy/MM/dd HH:mm' }}
              </span>
              <span *ngIf="!sensor.LastUpdate">
                Never
              </span>
            </td>
          </tr>
        </tbody>

      </table>
      <div class="buttons">
        <button mat-button (click)="editing = true" class="mr-1">EDIT SETTINGS</button>
        <button mat-raised-button (click)="update()" color="primary">SAVE</button>
      </div>
    </div>
    <div *ngIf="editing">
      <table datatable [dtOptions]="dtOptions">
        <thead>
          <tr style="background-color: #f5f5f5">
            <th style="min-width: 50px">

            </th>
            <th *ngFor="let parameter of sensors[0].Parameters" style="text-align: center !important">
              {{ parameter.Name }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sensor of sensors">
            <th style="border-bottom: 1px solid #f5f5f5; vertical-align: middle; background-color: #f5f5f5; color: #f44336">
              <button mat-button (click)="loadHistory(sensor)"><img src="{{ icon(sensor) }}" style="margin-right: 6px" /> {{ sensor.Name }}</button>
              <span style="display: none"> {{sensorService.gatewayName(sensor.GatewayId)}}</span>
            </th>
            <td *ngFor="let parameter of sensor.Parameters" style="border-bottom: 1px solid #f5f5f5; text-align: center">
              <input type="number" [(ngModel)]="parameter.Value" style="border: none; width: 100%; text-align: center" />
            </td>
            <td>
              <button mat-icon-button (click)="updateSettings(sensor)">
                <mat-icon>offline_bolt</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="buttons">
        <button mat-button (click)="editing = false" color="primary" class="mr-1">CANCEL</button>
        <button mat-raised-button (click)="update()" color="primary">SAVE</button>
      </div>
    </div>
      
  </div>

  <div *ngIf="sensorService.viewHistory && sensorService.sensorHistory">
    <h5>{{ sensorService.selectedSensor.Name }} - History</h5>
    <table datatable [dtOptions]="dtOptions">
      <thead>
        <tr style="background-color: #f5f5f5">
          <th>
            Date
          </th>
          <th>
            Packet
          </th>
          <th>
            RSSI
          </th>
          <th>
            NS
          </th>
          <th>
            Values
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sensor of sensorService.sensorHistory">
          <td>
            {{ sensor.DateCreated | date: 'yyyy/MM/dd HH:mm' }}
          </td>
          <td>
            {{ packetType(sensor.PacketType) }}
          </td>
          <td>
            {{ sensor.RSSI }}
          </td>
          <td>
            {{ sensor.NS }}
          </td>
          <td>
            {{ sensor.Values | json }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="buttons">
      <button mat-raised-button (click)="exitHistory()" color="primary">EXIT</button>
    </div>
  </div>

</div>
