import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { SensorService, SensorData } from '../../services/sensor.service';

@Component({
  selector: 'app-server',
  templateUrl: './server.component.html'
})
export class GatewayServerComponent implements OnInit {
  
  constructor(
    private applicationService: ApplicationService,
    private sensorService: SensorService
  ) {
    this.applicationService.title = "Gateway Server";
    this.sensorService.dataPackets = [];
  }

  get dataPackets(): SensorData[] {
    return this.sensorService.dataPackets.reverse();
  }

  packetType(index: number): string {
    return this.sensorService.packetType(index);
  }
  

  ngOnInit() {
    $('#output').height(window.innerHeight - 115);
  }

  trackPacket(index, packet) {
    return packet ? packet.Id : undefined;

  }


}
