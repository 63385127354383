import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApplicationService, UserType } from './application.service';


@Injectable({ providedIn: 'root' })
export class SiteService { 

  sites: Site[];
  selectedSite: Site;


  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private http: HttpClient) {
    
  }
    
  public load() {
    this.http.get<any>(this.applicationService.apiServiceUrl + '/Sites/Load')
      .toPromise().then(response => {
        if (response.Success) {
          this.sites = response.Data as Site[];
          if (!this.applicationService.isAdmin && this.sites.length == 1) {
            this.open(this.sites[0]);
          }
        }
      });
  }

  open(site: Site) {
    this.applicationService.site = site.Name;
    this.selectedSite = site;
    this.router.navigate(['/perimeter']);
  }

  public save(site: Site) {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Sites/Update', site)
      .toPromise().then(response => {
        if (response.Success) {
          if (!site.Id) {
            this.sites.push(response.Data as Site);
          }
          this.applicationService.showSuccess("Site updated successfully");
        }
      });
  }

}

export class Site {
  Id: string;
  Name: string;
  Latitude: number;
  Longitude: number;
}
