import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApplicationService, UserType } from './application.service';

@Injectable({ providedIn: 'root' })
export class SettingsService {

  settings: Setting[];

  constructor(
    private applicationService: ApplicationService,
    private http: HttpClient) {
    
  }

  public get(name: string): string {
    var setting = this.settings.find(i => i.Name == name);
    if (setting) {
      return setting.Value;
    }
    return "";
  }
  
  public load() {
    this.http.get<any>(this.applicationService.apiServiceUrl + '/Settings/Load')
      .toPromise().then(response => {
        if (response.Success) {
          this.settings = response.Data as Setting[];
          this.applicationService.settings = this.settings;
        }
      });

  }
  
  public save() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Settings/Save', this.settings)
      .toPromise().then(response => {
        if (response.Success) {
          this.applicationService.showSuccess("Settings updated successfully");
        }
      });
  }

}

export class Setting {
  Id: string;
  Name: string;
  Value: string;
  Description: string;
  Secure: boolean;
}
