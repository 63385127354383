import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sensor, SensorService, SensorType } from '../../services/sensor.service';
import { SiteService } from '../../services/site.service';
import { SettingsService } from '../../services/settings.service';
import { Subscription } from 'rxjs';
import { GatewayService, Gateway } from '../../services/gateway.service';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html'
})
export class SensorComponent implements OnInit {

  subscription: Subscription;

  constructor(
    public sensorDialog: MatDialogRef<SensorComponent>,
    public siteService: SiteService,
    public settingsService: SettingsService,
    public sensorService: SensorService,
    public gatewayService: GatewayService,
    @Inject(MAT_DIALOG_DATA) public data: Sensor) {
    
  }

  ngOnInit() {
  
    //this.subscription = this.sensorService.navItem$
      //.subscribe(item => this.data.Serial = item);
    if (!this.data.Id) {
      this.data.GatewayId = this.gatewayService.gateways[0].Id;
    }
  }

  onNoClick(): void {
    this.data = null;
    this.sensorDialog.close();
  }

  get gateways(): Gateway[] {
    return this.gatewayService.gateways;
  }

  get sensorTypes(): SensorType[] {
    return this.sensorService.sensorTypes;
  }
  
  
}
