import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationService } from '../../services/application.service';
import { Router } from '@angular/router';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  hide = true;
  username: string;
  password: string;
  error: string;

  constructor(
    private http: HttpClient,
    public applicationService: ApplicationService,
    public settingsService: SettingsService,
      private router: Router
  ) {
    let loginComponent = this;
    window.addEventListener("keydown", function (e) {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        loginComponent.login();
      }
    });
  }
  

  ngOnInit() {
   
  }

  login() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/User/Login', { Username: this.username, Password: this.password })
      .toPromise().then(response => {
          if (response.Success) {
            this.applicationService.user = response.Data;
            localStorage.setItem("usrToken", response.Data.Token);
            this.router.navigate(['/sites']);
            this.settingsService.load();
          }
      });
  }

}
