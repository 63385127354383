
<div style="padding: 12px; padding-bottom: 20px; background-color: #000; color: #fff; font-size: 12px; overflow-y: scroll" id="output">

  <div *ngIf="!dataPackets || !dataPackets.length">
    Waiting for gateway packets...
  </div>
  <div *ngIf="dataPackets && dataPackets.length">
    <div *ngFor="let packet of dataPackets; trackBy: trackPacket">
      <table style="width: auto" class="animated fadeIn">
        <tr>
          <td style="width: 120px">
            Created
          </td>
          <td>
            : &nbsp;&nbsp; {{ packet.DateCreated | date: 'HH:mm:ss' }}
          </td>
        </tr>
        <tr>
          <td>
            Sensor
          </td>
          <td>
            : &nbsp;&nbsp; {{ packet.SensorName }}
          </td>
        </tr>
        <tr>
          <td>
            Packet Type
          </td>
          <td>
            : &nbsp;&nbsp; {{ packetType(packet.PacketType) }}
          </td>
        </tr>
        <tr>
          <td>
            RSSI
          </td>
          <td>
            : &nbsp;&nbsp; {{ packet.RSSI }}
          </td>
        </tr>
        <tr>
          <td>
            SNR
          </td>
          <td>
            : &nbsp;&nbsp; {{ packet.NS }}
          </td>
        </tr>
        <tr>
          <td>
            Data
          </td>
          <td>
            : &nbsp;&nbsp; {{ packet.Values | json }}
          </td>
        </tr>
      </table>
      <hr style="border-top: 1px solid rgba(255, 255, 255, 0.7)" />
    </div>
  </div>
</div>
