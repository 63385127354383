<mat-progress-bar mode="indeterminate" class="app-progress" id="loader"></mat-progress-bar>

<div id="main-content" class="animated fadeIn">

  <mat-sidenav-container style="padding: 0">
    <mat-sidenav #snav [opened]="user && !mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" style="width: 280px; border-right: 1px solid #dedede; height: 100%;" >
      <div style="text-align: center;padding-bottom: 2px; border-bottom: 1px solid #f44336">
        <img src="/assets/img/lixodex-bar.png" style="max-height: 70px; margin: 8px 0 0 -4px" (click)="snav.toggle()" />
        <div *ngIf="applicationService.site" style="text-transform: uppercase; background-color: #f44336; margin-bottom: 8px; color: #fff; padding: 4px">
          {{ applicationService.site }}
        </div>
        <table style="margin: 0 0 8px 18px; cursor: pointer; background-color: #f5f5f5; width: 248px" [matMenuTriggerFor]="menu" *ngIf="user">
          <tr>
            <td style="text-align: center; padding-top: 8px; vertical-align: middle; width: 42px; border-right: 1px solid #fff">
              <mat-icon inline="true" style="font-size: 32px; line-height: 30px;">person</mat-icon>
            </td>
            <td style="text-align: left; vertical-align: middle; padding-left: 8px">
              <div style="font-size: 12px; font-weight: bold">
                {{ user.FirstName + ' ' + user.LastName }}
              </div>
              <div style="font-size: 10px; margin-top: -4px;">
                Role: {{ userType(user.UserType) }}
              </div>
            </td>
          </tr>
        </table>
        <mat-menu #menu="matMenu" style="width: 200px;">
          <button mat-menu-item><mat-icon color="primary">how_to_reg</mat-icon> <span>My Profile</span></button>
          <button mat-menu-item (click)="logout()"><mat-icon color="primary">power_settings_new</mat-icon> <span>Log Out</span></button>
        </mat-menu>
      </div>
      <mat-nav-list style="padding-top: 0" (click)="mobileQuery.matches && snav.toggle()">

        <a mat-list-item *ngIf="applicationService.isAdmin" routerLink="users" routerLinkActive="active-route" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">group</mat-icon> Users</a>

        <a mat-list-item *ngIf="showSites" routerLink="sites" routerLinkActive="active-route" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">language</mat-icon> My Sites</a>
        <a mat-list-item *ngIf="showPerimeter" routerLink="perimeter" routerLinkActive="active-route" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">vpn_lock</mat-icon> Live Perimeter</a>
        <a mat-list-item *ngIf="showPerimeter && applicationService.isAdmin" routerLink="gateways" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">settings_remote</mat-icon> Gateways</a>
        <a mat-list-item *ngIf="showPerimeter && applicationService.isAdmin" routerLink="equipment" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">settings_input_antenna</mat-icon> Equipment Settings</a>
        <a mat-list-item *ngIf="applicationService.isAdmin" routerLink="parameters" routerLinkActive="active-route" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">code</mat-icon> Sensor Parameters</a>
        <a mat-list-item *ngIf="applicationService.isAdmin" routerLink="settings" routerLinkActive="active-route" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">settings</mat-icon> Application Settings</a>
        <a mat-list-item *ngIf="showPerimeter && applicationService.isAdmin" routerLink="server" routerLinkActive="active-route" style="border-bottom: 1px solid #dedede; font-weight: bold"><mat-icon class="text-secondary mr-3">storage</mat-icon> Gateway Server</a>

      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content style="padding: 8px;">
      <mat-toolbar style="height: 50px; margin-bottom: 8px;" color="primary" *ngIf="user">
        <button mat-icon-button (click)="snav.toggle()" id="menu_button"><mat-icon>menu</mat-icon></button>
        <img *ngIf="mobileQuery.matches" src="/assets/img/lixodex-bar.png" style="max-height: 50px; margin-left: 15px" />
        <div *ngIf="!mobileQuery.matches">{{ applicationService.title }}</div>
      </mat-toolbar>
      <div style="margin: 0 2px">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
