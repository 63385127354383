import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ValidationService } from './validator';
import { Setting } from './settings.service';


@Injectable({ providedIn: 'root' })
export class ApplicationService { 
  
  public apiServiceUrl: string;
  UserType = UserType;
  public user: User;
  title = 'Lixodex';
  site = "";
  settings: Setting[];
  
  constructor(
        private validationService: ValidationService,
        private snackBar: MatSnackBar,
        private router: Router,
        private http: HttpClient) {
    
    this.apiServiceUrl = this.isLocal ? "http://localhost:63690/api" : "/api";

  }
  
  public login() {
    this.router.navigate(['/login']);
  }

  get isLocal(): boolean {
    return window.location.href.indexOf('localhost') > -1;
  }

  get isAdmin(): boolean {
    if (this.user) {
      return this.user.UserType == UserType.Admin;
    }
    return false;
  }

  get isUser(): boolean {
    if (this.user) {
      return this.user.UserType == UserType.User;
    }
    return false;
  }

  public showError(message: string) {
    if (message) {
      this.snackBar.open(message, 'Dismiss', {
        duration: 6000,
        panelClass: 'error-snack'
      });
    }
  }

  public showSuccess(message: string) {
    if (message) {
      this.snackBar.open(message, 'Ok', {
        duration: 4000,
        panelClass: 'success-snack'
      });
    }
  }

  public validate(fieldName: string): string {
      let error = this.validationService.Validate(fieldName);
      return error;
  }

}

export class User {
  Username: string;
  UserType: UserType;
  EmailAddress: string;
  Id: string;
  LastName: string;
  FirstName: string;
  MobileNumber: string;
  AlarmNotify: boolean;
  Sites: string[]
}

export enum UserType {
  Admin = 1,
  User,
  SuperUser
}
