import { Component, OnInit } from '@angular/core';
import { ApplicationService, User, UserType } from '../../services/application.service';
import { SettingsService, Setting } from '../../services/settings.service';
import { UsersService } from '../../services/user.service';
import { SiteService, Site } from '../../services/site.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  UserType = UserType;
  selectedSite: Site;

  constructor(
    private applicationService: ApplicationService,
    private siteService: SiteService,
    public usersService: UsersService
  ) {
    this.applicationService.title = "Lixodex Users";
    this.usersService.load();
  }

  get sites(): Site[] {
    return this.siteService.sites;
  }

  get users(): User[] {
    return this.usersService.users;
  }

  get selectedUser(): User {
    return this.usersService.selectedUser;
  }

  ngOnInit() {
    this.dtOptions = {
      order: [0, "asc"],
      lengthMenu: [10, 25, 50]
    };
  }

  onSiteSelection(siteId: string) {
    if (this.usersService.selectedUser.Sites.indexOf(siteId) < 0) {
      this.usersService.selectedUser.Sites.push(siteId);
    }
    this.selectedSite = null;
  }
  
  removeSite(sites: MatListOption[]) {
    for (var i = 0; i < sites.length; i++) {
      var index = this.usersService.selectedUser.Sites.indexOf(sites[i].value);
      this.usersService.selectedUser.Sites.splice(index, 1);
    }
  }
  
  get userSites(): string {
    return this.selectedUser.Sites.join(", ");
  }

  getSiteName(siteId: string) {
    let site = this.sites.find(i => i.Id == siteId);
    return site.Name;
  }

  get isAdmin(): boolean {
    return this.selectedUser.UserType == UserType.Admin;
  }

  select(user: User) {
    this.usersService.selectedUser = user;
  }

  add() {
    this.usersService.add();
  }

  save() {
    this.usersService.save();
  }

  cancel() {
    this.usersService.selectedUser = null;
  }
  
}
