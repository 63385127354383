
<div>

  <agm-map [latitude]="mapLat()" [longitude]="mapLng()" [mapTypeId]="mapTypeId" [zoom]="18">

    <agm-marker *ngFor="let tracker of trackers; let i = index"
                [latitude]="tracker.Latitude"
                [longitude]="tracker.Longitude"
                [markerDraggable]="applicationService.isAdmin && !lock"
                (dragEnd)="trackerDragEnd(tracker, $event)"
                [iconUrl]="icon(tracker)">
      <agm-info-window>
        <table>
          <tr>
            <td style="font-weight: bold;">
              Tracker Name
            </td>
            <td>
              {{ tracker.Name }}
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">
              Status
            </td>
            <td>
              {{ status(tracker) }}
            </td>
          </tr>
          <tr *ngIf="tracker.SensorStatus == 1 && !applicationService.isUser">
            <td></td>
            <td style="padding-top: 8px"><button (click)="resetTracker(tracker)">Reset</button></td>
          </tr>
          <tr *ngIf="applicationService.isAdmin">
            <td></td>
            <td style="padding-top: 8px"><button (click)="deleteTracker(tracker)">Delete</button></td>
          </tr>
        </table>
      </agm-info-window>
    </agm-marker>


    <agm-marker *ngFor="let sensor of sensors; let i = index"
                [latitude]="sensor.Latitude"
                [longitude]="sensor.Longitude"
                [markerDraggable]="applicationService.isAdmin && !lock"
                (dragEnd)="markerDragEnd(sensor, $event)"
                [iconUrl]="icon(sensor)">
      <agm-info-window>
        <table>
          <tr>
            <td style="font-weight: bold; width: 90px">
              Gateway ID
            </td>
            <td>
              {{ sensorService.gatewayName(sensor.GatewayId) }}
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">
              Sensor Name
            </td>
            <td>
              {{ sensor.Name }}
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">
              Voltage
            </td>
            <td>
              {{ sensor.Voltage / 10 }} V
            </td>
          </tr>
          <tr *ngIf="applicationService.isAdmin">
            <td style="font-weight: bold;">
              RSSI
            </td>
            <td>
              {{ sensor.RSSI }} dBm
            </td>
          </tr>
          <tr *ngIf="applicationService.isAdmin">
            <td style="font-weight: bold;">
              SNR
            </td>
            <td>
              {{ sensor.NS }}
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">
              Status
            </td>
            <td>
              {{ status(sensor) }}
            </td>
          </tr>
          <tr *ngIf="sensor.SensorStatus == 1 && !applicationService.isUser">
            <td></td>
            <td style="padding-top: 8px"><button (click)="reset(sensor)">Reset</button></td>
          </tr>
          <tr *ngIf="applicationService.isAdmin">
            <td></td>
            <td style="padding-top: 8px"><button (click)="update(sensor)">Update</button></td>
          </tr>
          <tr *ngIf="applicationService.isAdmin">
            <td></td>
            <td style="padding-top: 8px"><button (click)="delete(sensor)">Delete</button></td>
          </tr>
        </table>
      </agm-info-window>
    </agm-marker>
  </agm-map>
</div>

<div class="buttons">
  <button mat-button class="mr-1" (click)="lock = !lock">
    {{ lock ? 'MOVE SENSORS' : 'LOCK SENSORS'}}
  </button>
  <button mat-button (click)="addTracker()" class="mr-1" *ngIf="applicationService.isAdmin">ADD A TRACKER</button>
  <button mat-button (click)="add()" class="mr-1" *ngIf="applicationService.isAdmin">ADD A SENSOR</button>
  <button mat-button (click)="roadMap = !roadMap" class="mr-1">{{ roadMap ? 'SATELLITE VIEW' : 'ROAD MAP'  }}</button>
  <div *ngIf="trackers && trackers.length > 0" style="display: inline-block; margin-bottom: -20px">
    <mat-form-field>
      <mat-select placeholder="Tracking Device" [(ngModel)]="selectedTrackerId">
        <mat-option *ngFor="let tracker of trackers" [value]="tracker.Id">
          {{ tracker.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
