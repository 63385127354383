import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApplicationService, UserType } from './application.service';


@Injectable({ providedIn: 'root' })
export class ParameterService { 

  parameters: SensorParameter[];
  
  constructor(
    private applicationService: ApplicationService,
    private http: HttpClient) {
    
  }
    
  public load() {
    this.http.get<any>(this.applicationService.apiServiceUrl + '/Parameters/Load')
      .toPromise().then(response => {
        if (response.Success) {
          this.parameters = response.Data as SensorParameter[];
        }
      });
  }
  
  public save() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Parameters/Save', this.parameters)
      .toPromise().then(response => {
        if (response.Success) {
          this.parameters = response.Data as SensorParameter[];
          this.applicationService.showSuccess("Parameters updated successfully");
        }
      });
  }

}

export class SensorParameter {
  Id: string;
  SensorTypeId: number;
  ParameterName: string;
  Description: string;
  DefaultValue: number;
}
