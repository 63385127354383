import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { ComponentModule } from './modules/component.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceInterceptor } from './services/interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ServiceInterceptor, multi: true },
];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ComponentModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBD9Xi0dhkVm0mb_bUaRnxCROapm5XoJdI'
    }),
    BsDropdownModule.forRoot()
  ],
  providers: [
    CookieService,
    MatSnackBar,
    httpInterceptorProviders,
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
