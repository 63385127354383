import { Component, OnInit } from '@angular/core';

import { ApplicationService } from '../../services/application.service';
import { GatewayService, Gateway } from '../../services/gateway.service';

@Component({
  selector: 'app-gateways',
  templateUrl: './gateways.component.html'
})
export class GatewayComponent implements OnInit {

  dtOptions: DataTables.Settings = {};

  constructor(
    private applicationService: ApplicationService,
    private gatewayService: GatewayService
  ) {
    this.applicationService.title = "Gateways";
    this.gatewayService.selectedGateway = null;
  }

  get gateway(): Gateway {
    return this.gatewayService.selectedGateway;
  }
  
  ngOnInit() {
    this.gatewayService.load();
    this.dtOptions = {
      order: [0, "asc"],
      lengthMenu: [10, 25, 50]
    };
  }

  get editing(): boolean {
    return this.gatewayService.selectedGateway != null;
  }
  
  get gateways(): Gateway[] {
    return this.gatewayService.gateways;
  }

  add() {
    this.gatewayService.add();
  }

  save() {
    this.gatewayService.save();
  }

  cancel() {
    this.gatewayService.cancel();
  }

  edit(gateway: Gateway) {
    this.gatewayService.edit(gateway);
  }
  

}
