import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HubConnection, HubConnectionState } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import * as moment from 'moment';
import { ApplicationService, UserType } from './application.service';
import { SiteService } from './site.service';
import { BehaviorSubject } from 'rxjs';
import { GatewayService } from './gateway.service';

@Injectable({ providedIn: 'root' })
export class SensorService {

  private hubConnection: HubConnection;
  sensors: Sensor[];
  selectedSensor: Sensor;
  dataPackets: SensorData[] = [];
  sensorTypes: SensorType[] = [];
  sensorHistory: SensorData[] = [];
  viewHistory = false;
  updateSensors = false;

  private _navItemSource = new BehaviorSubject<string>("");
  // Observable navItem stream
  navItem$ = this._navItemSource.asObservable();
  // service command
  changeNav(value) {
    this._navItemSource.next(value);
  }

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private siteService: SiteService,
    private gatewayService: GatewayService,
    private http: HttpClient) {

    this.sensorTypes.push({ Id: 1, Name: "Fence" });
    
  }
  
  startWebSocket() {

    this.updateSensors = true;

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.applicationService.apiServiceUrl.replace('api', 'hubs') + '/perimeter', {
        accessTokenFactory: () => localStorage.getItem("usrToken")
      }).build();

    this.hubConnection
      .start()
      .then(() => {
        console.log("Live update connected!");
        this.hubConnection.invoke("SensorFeed", this.siteService.selectedSite).catch(function (err) {
          return console.error(err.toString());
        });
      }).catch(err => this.applicationService.showError('Live sensor update was not initiated'));

    this.hubConnection.onclose(() => {
      this.startWebSocket();
    });
    
    this.hubConnection.on('SensorUpdate', (sensorData: Sensor[]) => {
      if (this.updateSensors) {
        sensorData.forEach((item) => {
          if (this.gatewayService.gateways.find(i => i.Id == item.GatewayId) != null) {
            let s = this.sensors.find(i => i.Id == item.Id);
            if (s) {
              this.sensors.splice(this.sensors.indexOf(s), 1);
              s = item;
            }
            this.sensors.push(item);
          }
        });
      }
    });
    
    this.hubConnection.on('GatewayUpdate', (packet: SensorData) => {
      if (!this.dataPackets.find(i => i.Id == packet.Id)) {
        this.dataPackets.push(packet);
      }
    });

    this.hubConnection.on('NewSensor', (id: string) => {
      // used for populating the sensor serial automatically when adding new sensors, disabled for now...
      //this.changeNav(id);
    });

  }

 icon(sensor: any) {
    if (sensor.SensorStatus == SensorStatus.Alarm) {
      return '/assets/img/markers/red-dot.png';
    }
    else {
      if (sensor.LastUpdate) {
        let now = moment();
        let updated = moment(sensor.LastUpdate);
        let heartbeat = sensor.Parameters.find(i => i.Name == "HeartBeat");
        if (updated >= now.add((-10 * heartbeat.Value) - (60 * 4), 'minutes')) { // 60 * 4 means 4 hours garce period
          return '/assets/img/markers/green-dot.png';
        }
      }
    }
    return '/assets/img/markers/grey-dot.png';
  }

  status(sensor: any) {
    if (sensor.SensorStatus == SensorStatus.Alarm) {
      return 'Alarm!';
    }
    else {
      let now = moment();
      let updated = moment(sensor.LastUpdate);
      let heartbeat = sensor.Parameters.find(i => i.Name == "HeartBeat");
      if (updated >= now.add((-10 * heartbeat.Value) - (60 * 4), 'minutes')) {
        return 'Active';
      }
    }
    return "InActive";
  }

  stopWebSocket() {
    this.updateSensors = false;
  }

  packetType(index: number): string {
    if (index == 1) {
      return "HeartBeat";
    }
    else if (index == 2) {
      return "DataSample";
    }
  }

  connect() {
   
  }

  gatewayName(id: string): string {
    if (this.gatewayService.gateways) {
      return this.gatewayService.gateways.find(i => i.Id == id).Name;
    }
    return "";
  }

  load() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Sensors/Load', this.siteService.selectedSite)
      .toPromise().then(response => {
        if (response.Success) {
          this.sensors = response.Data as Sensor[];
          if (this.selectedSensor != null && this.viewHistory) {
            this.loadHistory(this.selectedSensor);
          }
          this.connect();
        }
      });
  }

  loadHistory(sensor: Sensor) {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Sensors/History', sensor)
      .toPromise().then(response => {
        if (response.Success) {
          this.selectedSensor = sensor;
          this.sensorHistory = response.Data as SensorData[];
          this.viewHistory = true;
        }
      });
  }

  exitHistory() {
    this.viewHistory = false;
    this.selectedSensor = null;
    this.sensorHistory = [];
  }

  public save(sensor: Sensor) {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Sensors/Save', sensor)
      .toPromise()
      .then(response => {
      if (response.Success) {
        this.applicationService.showSuccess("Sensor update complete!");
      }
    });
  }

  public update() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Sensors/Update', this.sensors)
      .toPromise()
      .then(response => {
        if (response.Success) {
          this.applicationService.showSuccess("Sensor update complete!");
        }
      });
  }

  public delete(sensor: Sensor) {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Sensors/Delete', sensor).toPromise()
      .then(response => {
        if (response.Success) {
          var index = this.sensors.indexOf(sensor);
          this.sensors.splice(index, 1);
        }
      });
  }
  
}

export class Sensor {
  Id: string;
  SensorTypeId: number;
  Name: string;
  Serial: string;
  GatewayId: string;
  Latitude: number;
  Longitude: number;
  RSSI: number;
  NS: number;
  Voltage: number;
  Parameters: Parameter[];
  SensorStatus: SensorStatus;
  LastUpdate: Date;
}

export class Parameter {
  Name: string;
  Value: number;
}

export enum SensorStatus {
  Normal = 0,
  Alarm
}

export class SensorType {
  Id: number;
  Name: string;
}

export class SensorData {
  Id: number;
  Serial: string;
  Gateway: string;
  SensorName: string;
  DateCreated: Date;
  PacketType: number;
  SensorType: number;
  RSSI: number;
  NS: number;
  Values: number[];
}
