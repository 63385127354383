import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HubConnection, HubConnectionState } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { ApplicationService, UserType } from './application.service';
import { SiteService } from './site.service';
import { BehaviorSubject } from 'rxjs';
import { SensorStatus } from './sensor.service';

@Injectable({ providedIn: 'root' })
export class TrackerService {

  private hubConnection: HubConnection;
  trackers: Tracker[];
  selectedTracker: Tracker;

  constructor(
    private applicationService: ApplicationService,
    private siteService: SiteService,
    private http: HttpClient) {
        
  }
  
  startWebSocket() {
    
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.applicationService.apiServiceUrl.replace('api', 'hubs') + '/perimeter', {
        accessTokenFactory: () => localStorage.getItem("usrToken")
      }).build();

    this.hubConnection
      .start()
      .then(() => {
        console.log("Live tracker connected!");
        this.hubConnection.invoke("SensorFeed", this.siteService.selectedSite).catch(function (err) {
          return console.error(err.toString());
        });
      }).catch(err => this.applicationService.showError('Live tracker update was not initiated'));

    this.hubConnection.onclose(() => {
      this.startWebSocket();
    });
    
    this.hubConnection.on('TrackerUpdate', (tracker: Tracker) => {
     
      let s = this.trackers.find(i => i.Id == tracker.Id);
      if (s) {
        this.trackers.splice(this.trackers.indexOf(s), 1);
        s = tracker;
      }
      else {
        this.trackers.push(tracker);
      }
     
    });
  }
  
  connect() {
   
  }

  load() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Trackers/Load', this.siteService.selectedSite)
      .toPromise().then(response => {
        if (response.Success) {
          this.trackers = response.Data as Tracker[];
          this.connect();
        }
      });
  }
  
  public save(tracker: Tracker) {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Trackers/Save', tracker)
      .toPromise()
      .then(response => {
      if (response.Success) {
        this.applicationService.showSuccess("Tracker update complete!");
      }
    });
  }
  
  public delete(tracker: Tracker) {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Trackers/Delete', tracker).toPromise()
      .then(response => {
        if (response.Success) {
          var index = this.trackers.indexOf(tracker);
          this.trackers.splice(index, 1);
        }
      });
  }
  
}

export class Tracker {
  Id: string;
  SiteId: string;
  Name: string;
  IMEI: string;
  Latitude: number;
  Longitude: number;
  SetLatitude: number;
  SetLongitude: number;
  SensorStatus: SensorStatus;
  LastUpdate: Date;
}

