import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValidationService { 
  
    validations: Array<ValidationError> = [];

    public Add(validation: ValidationError) {
        this.validations.push(validation);
    }

    public Clear() {
        this.validations = [];
    }

    public Validate(fieldName: string): string {
        let error = this.validations.find((item) => item.FieldName == fieldName);
        if (error) {
            return error.Message;
        }
        return '';
    }
}

export class ValidationError {
    FieldName: string;
    Message: string;
}

