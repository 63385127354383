<h2 mat-dialog-title>Add a New Sensor</h2>
<mat-dialog-content>
  <div class=" pt-2">

    <mat-form-field>
      <mat-select placeholder="Gateway" [(ngModel)]="data.GatewayId">
        <mat-option *ngFor="let gateway of gateways" [value]="gateway.Id">
          {{ gateway.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Sensor Type" [(ngModel)]="data.SensorTypeId">
        <mat-option *ngFor="let sensorType of sensorTypes" [value]="sensorType.Id">
          {{ sensorType.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input placeholder="Serial" matInput type="number" [(ngModel)]="data.Serial" />
    </mat-form-field>

    <mat-form-field>
      <input placeholder="Sensor Name" matInput [(ngModel)]="data.Name" />
    </mat-form-field>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCEL</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">OK</button>
</mat-dialog-actions>
