import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ApplicationService } from '../../services/application.service';
import { SiteService, Site } from '../../services/site.service';
import { MatListOption } from '@angular/material/list';
import { SensorService } from '../../services/sensor.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html'
})
export class SitesComponent implements OnInit {

  site: Site;
  editing = false;
  dtOptions: DataTables.Settings = {};

  constructor(
    private applicationService: ApplicationService,
    public siteService: SiteService,
    public sensorService: SensorService
  ) {
    this.applicationService.title = "My Sites";
    this.applicationService.site = "";
    this.siteService.selectedSite = null;
  }
  

  ngOnInit() {
    this.siteService.load();
    this.dtOptions = {
      order: [0, "asc"],
      lengthMenu: [10, 25, 50]
    };
  }

  get sites(): Site[] {
    return this.siteService.sites;
  }

  add() {
    this.editing = true;
    this.site = new Site();
  }

  save() {
    this.siteService.save(this.site);
    this.editing = false;
  }

  cancel() {
    this.editing = false;
    this.site = null;
  }

  edit(site: Site) {
    this.site = site;
    this.editing = true;
  }

  open(site: Site) {
    this.siteService.open(site);
    //this.sensorService.load();
  }

}
