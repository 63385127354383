import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';

import { ApplicationService } from './application.service';
import { ValidationService } from './validator';

@Injectable({ providedIn: 'root' })
export class ServiceInterceptor implements HttpInterceptor {

  constructor(
      private applicationService: ApplicationService,
      private validationService: ValidationService
  ) { }

  counter = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    var authReq = request;
    this.counter += 1;

    var jwt = localStorage.getItem("usrToken") || false;
    if(jwt){
      authReq = request.clone({
        headers: request.headers.set('Authorization', "Bearer " + jwt)
      });
    }

      if (this.counter == 1) {
        this.validationService.Clear();
        let loader = document.getElementById('loader');
        if (loader) {
          loader.style.display = 'block';
        }
    }
    
    return next.handle(authReq)
      .pipe(
        tap(
        event => {
          if (event instanceof HttpResponse) { 
            if (event.body && !event.body.Success) {
                if (event.body.ValidationErrors && event.body.ValidationErrors.length) {
                    event.body.ValidationErrors.forEach((item) => {
                        this.validationService.Add(item);
                    });
                }
                else {
                    this.applicationService.showError(event.body.Message);
                }
            }
          }
        }
        , response => {
          if (response.status != 200) {
            if (response.status == 401) {
                this.applicationService.login();
              }
              else {
                this.applicationService.showError(response.message);
              }
          }
        }),
        finalize(() => {
          this.counter -= 1;
          if (this.counter <= 1) {
            let loader = document.getElementById('loader');
            if (loader) {
              loader.style.display = 'none';
            }
          }
          })
      );
   
  }
}

