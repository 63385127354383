import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { SettingsService, Setting } from '../../services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  
  constructor(
    private applicationService: ApplicationService,
    public settingsService: SettingsService
  ) {
    this.applicationService.title = "Application Settings";
  }
  

  ngOnInit() {
    
  }

  get settings(): Setting[] {
    return this.settingsService.settings;
  }

  add() {
    this.settingsService.settings.push({ Id: null, Name: "", Value: "", Description: "", Secure: false })
  }

  save() {
    this.settingsService.save();
  }
  
}
