import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApplicationService } from './application.service';
import { SiteService } from './site.service';


@Injectable({ providedIn: 'root' })
export class GatewayService { 

  gateways: Gateway[];
  selectedGateway: Gateway;

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private siteService: SiteService,
    private http: HttpClient) {
    
  }
    
  public load() {
    this.selectedGateway = null;
    if (this.siteService.selectedSite) {
      this.http.post<any>(this.applicationService.apiServiceUrl + '/Gateway/Load', this.siteService.selectedSite)
        .toPromise().then(response => {
          if (response.Success) {
            this.gateways = response.Data as Gateway[];
          }
        });
    }
  }
  
  public save() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Gateway/Update', this.selectedGateway)
      .toPromise().then(response => {
        if (response.Success) {
          this.load();
        }
      });
  }

  public edit(gateway: Gateway) {
    this.selectedGateway = gateway;
  }

  public add() {
    this.selectedGateway = new Gateway();
    this.selectedGateway.SiteId = this.siteService.selectedSite.Id;
  }

  public cancel() {
    this.selectedGateway = null;
  }

}

export class Gateway {
  Id: string;
  SiteId: string;
  Serial: string;
  Name: string;
  IPAddress: string;
  LastUpdate: Date;
}
