<h2 mat-dialog-title>Add a New Tracking Device</h2>
<mat-dialog-content>
  <div class=" pt-2">

    <mat-form-field>
      <input placeholder="IMEI" matInput type="text" [(ngModel)]="data.IMEI" />
    </mat-form-field>

    <mat-form-field>
      <input placeholder="Device Name" matInput [(ngModel)]="data.Name" />
    </mat-form-field>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCEL</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">OK</button>
</mat-dialog-actions>
