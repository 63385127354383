
<div class="animated fadeIn">

  <table class="table table-hover" datatable [dtOptions]="dtOptions" *ngIf="users && users.length && !selectedUser" style="cursor: pointer">
    <thead>
      <tr >
        <td>
          Username
        </td>
        <td>
          Full Names
        </td>
        <td>
          Email Address
        </td>
        <td>
          Mobile Number
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users" (click)="select(user)">
        <td>{{ user.Username }}</td>
        <td>{{ user.FirstName }} {{ user.LastName }}</td>
        <td>{{ user.EmailAddress }}</td>
        <td>{{ user.MobileNumber }}</td>
      </tr>
    </tbody>
  </table>


  <div class="card" *ngIf="selectedUser">
    <div class="card-header">
      <h5 *ngIf="!selectedUser.Id">Add a New User</h5>
      <h5 *ngIf="selectedUser.Id">{{ selectedUser.FirstName }} {{ selectedUser.LastName }}</h5>
    </div>
    <div class="card-body">
      <div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Username</mat-label>
              <input matInput [(ngModel)]="selectedUser.Username" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput [(ngModel)]="selectedUser.FirstName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput [(ngModel)]="selectedUser.LastName" />
            </mat-form-field>
            <mat-form-field *ngIf="applicationService.isAdmin">
              <mat-select placeholder="User Type" [(ngModel)]="selectedUser.UserType">
                <mat-option [value]="1">
                  Admin
                </mat-option>
                <mat-option [value]="2">
                  User
                </mat-option>
                <mat-option [value]="3">
                  Super User
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">

            <mat-form-field>
              <mat-label>Email Address</mat-label>
              <input matInput [(ngModel)]="selectedUser.EmailAddress" />
            </mat-form-field>

            <mat-form-field style="margin-bottom: 10px">
              <mat-label>Mobile Number</mat-label>
              <input matInput [(ngModel)]="selectedUser.MobileNumber" />
            </mat-form-field>
            <mat-checkbox [(ngModel)]="selectedUser.AlarmNotify"> Receive Alarm Notifications</mat-checkbox>

            <div *ngIf="applicationService.isAdmin">
              <mat-form-field>
                <mat-select placeholder="Add a Site" (selectionChange)="onSiteSelection($event.value)" [(ngModel)]="selectedSite">
                  <mat-option *ngFor="let site of sites" [value]="site.Id">
                    {{ site.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="card" *ngIf="selectedUser.Sites && selectedUser.Sites.length">
                <div class="card-body">
                  <mat-selection-list #siteList>
                    <mat-list-option *ngFor="let site of selectedUser.Sites" [value]="site">
                      {{ getSiteName(site) }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
                <div class="card-footer text-right" *ngIf="siteList.selectedOptions.selected.length">
                  <button mat-button (click)="removeSite(siteList.selectedOptions.selected)">Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button mat-raised-button (click)="add()" class="mr-1" *ngIf="!selectedUser" color="primary">ADD A USER</button>
    <button mat-button (click)="cancel()" class="mr-1" *ngIf="selectedUser">CANCEL</button>
    <button mat-raised-button *ngIf="selectedUser" (click)="save()" color="primary">SAVE</button>
  </div>
</div>

