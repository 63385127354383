import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { MaterialDesignModule } from './material-design.module';

import { PerimeterComponent } from '../components/perimeter/perimeter.component';
import { LoginComponent } from '../components/login/login.component';
import { SitesComponent } from '../components/sites/sites.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { SensorComponent } from '../components/sensor/sensor.component';
import { GatewayServerComponent } from '../components/server/server.component';
import { UsersComponent } from '../components/users/users.component';
import { TrackerComponent } from '../components/tracker/tracker.component';
import { EquipmentComponent } from '../components/equipment/equipment.component';
import { ParametersComponent } from '../components/parameters/parameters.component';
import { GatewayComponent } from '../components/gateways/gateways.component';


@NgModule({
  declarations: [
    PerimeterComponent,
    LoginComponent,
    SitesComponent,
    SettingsComponent,
    SensorComponent,
    TrackerComponent,
    GatewayServerComponent,
    UsersComponent,
    EquipmentComponent,
    ParametersComponent,
    GatewayComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialDesignModule,
    DataTablesModule,
    AppRoutingModule,
    AgmCoreModule
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialDesignModule,
    DataTablesModule,
    AppRoutingModule
  ],
  entryComponents: [
    SensorComponent,
    TrackerComponent
  ]
})
export class ComponentModule {

}
