import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { ApplicationService, UserType, User } from './services/application.service';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { SettingsService } from './services/settings.service';
import { SiteService } from './services/site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  
  constructor(
    private http: HttpClient,
    public applicationService: ApplicationService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    public settingsService: SettingsService,
    public siteService: SiteService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  ngOnInit() {
    if (!this.applicationService.user) {
      this.http.get<any>(this.applicationService.apiServiceUrl + '/User/Load')
        .toPromise().then(response => {
          if (response.Success) {
            this.applicationService.user = response.Data;
            localStorage.setItem("usrToken", response.Data.Token);
            this.router.navigate(['/sites']);
            this.settingsService.load();
          }
        });
    }
    $('#main-content').show();
    if (!this.mobileQuery.matches) {
      $('#menu_button').click();
    }
    $("mat-sidenav-container").height(window.innerHeight);
  }

  get showSites(): boolean {
    return this.siteService.sites && (this.siteService.sites.length > 1 || this.applicationService.isAdmin);
  }

  get showPerimeter(): boolean {
    if (this.siteService.selectedSite) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  userType(userType: number): string {
    return UserType[userType];
  }

  get user(): User {
    return this.applicationService.user;
  }

  logout() {
    localStorage.removeItem("usrToken"); 
    window.location.reload(true);
  }
  
}
