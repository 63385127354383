
<div class="animated fadeIn">


  <table class="table table-hover" datatable [dtOptions]="dtOptions" *ngIf="sites && !editing && sites.length" style="cursor: pointer; width: 100%">
    <thead>
      <tr>
        <td>
          Name
        </td>
        <td>
          Latitude
        </td>
        <td>
          Longitude
        </td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let site of sites" (click)="applicationService.isAdmin && open(site)">
        <td>{{ site.Name }}</td>
        <td>{{ site.Latitude }}</td>
        <td>{{ site.Longitude }}</td>
        <td style="text-align: center">
          <button *ngIf="applicationService.isAdmin" (click)="edit(site)">
            EDIT
          </button>
        </td>
      </tr>
    </tbody>
  </table>


  <div *ngIf="editing" class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <h5>Site Details</h5>
        </div>
        <div class="card-body">
          <mat-form-field>
            <mat-label>Site Name</mat-label>
            <input matInput [(ngModel)]="site.Name" required />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Latitude</mat-label>
            <input matInput type="number" [(ngModel)]="site.Latitude" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Longitude</mat-label>
            <input matInput type="number" [(ngModel)]="site.Longitude" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button *ngIf="!editing" mat-raised-button (click)="add()" color="primary">CREATE A SITE</button>
    <button *ngIf="editing" mat-button (click)="cancel()" class="mr-1">CANCEL</button>
    <button *ngIf="editing" mat-raised-button (click)="save()" color="primary">SAVE</button>
  </div>
</div>

