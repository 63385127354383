
<div class="container" style="margin-top: 80px">
  <div class="animated mt-3 fadeIn" style="max-width: 305px; margin: auto;">
    <div class="card">
      <div class="card-header text-center p-1">
        <img src="/assets/img/lixodex-bar.png" />
      </div>
      <div class="card-body text-center">

        <h5 class="text-danger">Please Sign In</h5>
        <hr />
        
        <mat-form-field class="mt-2">
          <mat-label>Enter your username</mat-label>
          <input matInput [(ngModel)]="username" style="text-transform: none" />
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
        <div class="validation">{{ applicationService.validate('Username') }}</div>

        <mat-form-field>
          <mat-label>Enter your password</mat-label>
          <input [type]="hide ? 'password' : 'text'" matInput [(ngModel)]="password" style="text-transform: none" />
          <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>
        <div class="validation">{{ applicationService.validate('Password') }}</div>

        <div class="small muted" style="margin-top: 8px;">
          This is a private network. <br />Unauthorised access is prohibited
        </div>
      </div>
      <div class="card-footer text-right">
        <button mat-raised-button (click)="login()" color="primary">LOGIN</button>
      </div>
    </div>
  </div>
</div>
