
<div class="animated fadeIn">

  <div class="m-3" *ngIf="!gateways.length">
    There are no gateways loaded yet
  </div>

  <table class="table table-hover" datatable [dtOptions]="dtOptions" *ngIf="!editing && gateways.length" style="cursor: pointer">
    <thead>
      <tr>
        <td>
          Name
        </td>
        <td>
          Serial
        </td>
        <td>
          IP Address
        </td>
        <td>
          Last Update
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let gateway of gateways" (click)="applicationService.isAdmin && edit(gateway)">
        <td>{{ gateway.Name }}</td>
        <td>{{ gateway.Serial }}</td>
        <td>{{ gateway.IPAddress }}</td>
        <td>
          <span *ngIf="gateway.LastUpdate">
            {{ gateway.LastUpdate | date: 'yyyy/MM/dd HH:mm' }}
          </span>
          <span *ngIf="!gateway.LastUpdate">
            Never
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="editing" class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <h5>Gateway Details</h5>
        </div>
        <div class="card-body">
          <mat-form-field>
            <mat-label>Gateway Name</mat-label>
            <input matInput [(ngModel)]="gateway.Name" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Gateway Serial</mat-label>
            <input matInput [(ngModel)]="gateway.Serial" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button *ngIf="!editing" mat-raised-button (click)="add()" color="primary">ADD A GATEWAY</button>
    <button *ngIf="editing" mat-button (click)="cancel()" class="mr-1">CANCEL</button>
    <button *ngIf="editing" mat-raised-button (click)="save()" color="primary">SAVE</button>
  </div>

</div>
