import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackerService, Tracker } from '../../services/tracker.service';
import { SiteService } from '../../services/site.service';
import { SettingsService } from '../../services/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html'
})
export class TrackerComponent implements OnInit {

  subscription: Subscription;

  constructor(
    public trackerDialog: MatDialogRef<TrackerComponent>,
    public siteService: SiteService,
    public settingsService: SettingsService,
    public trackerService: TrackerService,
    @Inject(MAT_DIALOG_DATA) public data: Tracker) {
    
  }

  ngOnInit() {
    
  }

  onNoClick(): void {
    this.data = null;
    this.trackerDialog.close();
  }

}
