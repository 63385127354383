import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { ParameterService, SensorParameter } from '../../services/parameter.service';
import { SensorService, SensorType } from '../../services/sensor.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html'
})
export class ParametersComponent implements OnInit {
  
  constructor(
    private applicationService: ApplicationService,
    public parameterService: ParameterService,
    public sensorService: SensorService
  ) {
    this.applicationService.title = "Sensor Parameters";
    this.parameterService.load();
  }
  

  ngOnInit() {
    
  }

  get sensorTypes(): SensorType[] {
    return this.sensorService.sensorTypes;
  }

  get parameters(): SensorParameter[] {
    return this.parameterService.parameters;
  }

  add() {
    this.parameterService.parameters.push({ Id: null, SensorTypeId: 1, ParameterName: "", DefaultValue: null, Description: "" });
  }

  save() {
    this.parameterService.save();
  }
  
}
