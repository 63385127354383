<div class="animated fadeIn">
  <div class="card-body">
    <div *ngFor="let setting of settings" class="alert alert-secondary">
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Setting Name</mat-label>
            <input matInput [(ngModel)]="setting.Name" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Setting Value</mat-label>
            <input matInput [(ngModel)]="setting.Value" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-checkbox [(ngModel)]="setting.Secure"> Secure</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Description</mat-label>
            <input matInput [(ngModel)]="setting.Description" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button mat-button (click)="add()" class="mr-1">ADD A SETTING</button>
    <button mat-raised-button (click)="save()" color="primary">SAVE</button>
  </div>
</div>

