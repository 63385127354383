import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApplicationService, UserType, User } from './application.service';

@Injectable({ providedIn: 'root' })
export class UsersService {

  users: User[];
  selectedUser: User;

  constructor(
    private applicationService: ApplicationService,
    private http: HttpClient) {
    
  }

  public load() {
    this.http.get<any>(this.applicationService.apiServiceUrl + '/Users/Load')
      .toPromise().then(response => {
        if (response.Success) {
          this.users = response.Data as User[];
        }
      });

  }
  
  public save() {
    this.http.post<any>(this.applicationService.apiServiceUrl + '/Users/Save', this.selectedUser)
      .toPromise().then(response => {
        if (response.Success) {
          var user = response.Data as User;
          if (!this.selectedUser.Id) {
            this.users.push(user);
          }
          this.selectedUser = null;
          this.applicationService.showSuccess("User updated successfully");
        }
      });
  }

  public add() {
    this.selectedUser = new User();
    this.selectedUser.Sites = [];
  }

}

